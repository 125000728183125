
// TRACKING

    document.addEventListener("DOMContentLoaded", function() {
      setInterval(() => {
        const nblocalstorage = localStorage.getItem("northbeamIdentifyOrders");
        let parsedNblocalstorage = {};
        if (nblocalstorage !== null) {
          parsedNblocalstorage = JSON.parse(nblocalstorage);
        }

        if (!window.sessionStorage.orderData) {
          console.info("NB No Order Data");
          return;
        }
        const orderData = JSON.parse(window.sessionStorage.orderData);
        if (parsedNblocalstorage[orderData.orderId]) {
          console.info("NB Order Already Processed");
          return;
        } else {
          if(!orderData.emailAddress) {
            console.info("NB No email present");
            return;
          } else {
            if(orderData.emailAddress.includes("@")) {
              console.info("NB Identify Called");
              window.Northbeam.identify("email", orderData.emailAddress);
            } else {
              console.info("NB Not Valid Email");
              return;
            } 
          }
          parsedNblocalstorage[orderData.orderId] = true;
        }
        localStorage.setItem(
          "northbeamIdentifyOrders",
          JSON.stringify(parsedNblocalstorage),
        );
      }, 1000);
    });
    
document.addEventListener("DOMContentLoaded", function() {
    const progressBlocks = document.querySelectorAll('.progress-block');

    console.log(`Found ${progressBlocks.length} progress blocks.`);

    progressBlocks.forEach((blockContent, blockIndex) => {
        console.log(`Processing block ${blockIndex + 1} of ${progressBlocks.length}.`);

        // Query all visible progress items within this block
        const progressItems = blockContent.querySelectorAll('.progress-item:not([style*="display: none"])');

        console.log(`Found ${progressItems.length} visible progress items in block ${blockIndex + 1}.`);

        const totalItems = progressItems.length;
        const sections = 5; // We want 5 sections
        const sectionWidth = 100 / sections; // Each section should occupy 1/5th of the container
        const itemsPerSection = Math.ceil(totalItems / sections);
        console.log(`Each section has a width of: ${sectionWidth}%, items per section: ${itemsPerSection}`);

        let itemIndex = 0;

        for (let i = 0; i < sections; i++) {
            const remainingItems = totalItems - itemIndex;
            const itemsInThisSection = Math.min(itemsPerSection, remainingItems);
            const itemWidth = sectionWidth / itemsInThisSection;

            console.log(`Section ${i + 1}: ${itemsInThisSection} items, each with an adjusted width of ${itemWidth}%`);

            for (let j = 0; j < itemsInThisSection; j++) {
                const item = progressItems[itemIndex];
                
                // Apply width and reset any Flexbox settings that might interfere
                item.style.width = `${itemWidth}%`;
                item.style.flexGrow = '0';
                item.style.flexShrink = '0';
                item.style.flexBasis = 'auto'; // Ensures width is respected

                // Resetting border-radius and margins
                item.style.borderRadius = '0';
                item.style.marginRight = '0';

                // Apply border-radius to the first item in the section
                if (j === 0) {
                    item.style.borderTopLeftRadius = '15px';
                    item.style.borderBottomLeftRadius = '15px';
                }

                // Apply border-radius to the last item in the section
                if (j === itemsInThisSection - 1) {
                    item.style.borderTopRightRadius = '15px';
                    item.style.borderBottomRightRadius = '15px';
                    if (i < sections - 1) {
                        item.style.marginRight = '5px'; // Add margin-right except for the last section
                    }
                }

                itemIndex++;
            }
        }
    });
});
